<template>
  <div>
    <button
      class="consumer-metadata-toolbar-container btn-outline-blue w-100"
      @click="onClick"
    >
      <div v-for="meta in metadata" :key="meta.type" class="pt-2">
        <h5><i :class="meta.icon"></i>&nbsp;Add New Consumer Field</h5>
      </div>
    </button>
  </div>
</template>

<script>
// import draggable from "vuedraggable";

export default {
  components: {
    // draggable,
  },
  data() {
    return {
      element: "",
      globalID: 0,
      metadata: [
        {
          name: "",
          icon: "fas fa-plus-circle",
          type: "text",
          defaultValue: null,
          required: false,
          dvT: null,
          editable: true,
        },
      ],
    };
  },
  props: ["addNewField"],
  methods: {
    onChange(e) {
      this.element = e.draggedContext.element;
    },
    getNewMetadata() {
      return {
        id: this.globalID++,
        meta: {
          defaultValue: this.element.defaultValue,
          type: this.element.type,
          dvT: this.element.dvT,
          required: this.element.required,
          type: this.element.type,
          name: this.element.name,
          editable: true,
        },
      };
    },
    onClone(element) {
      this.element = element;
      return this.getNewMetadata();
    },
    onClick(element) {
      this.element = element;
      this.addNewField(this.getNewMetadata());

      // scroll modal to bottom
      const modalEle = document.getElementsByClassName("modal")[0];
      modalEle.scrollTop = modalEle.scrollHeight;
    },
  },
};
</script>
