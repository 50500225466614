<template>
  <ContentContainer>
    <template #title>
      <h4 class="text-left">
        <strong>{{ getClientInContext.client_name }}'s</strong> Consumers
      </h4>
    </template>
    <template #header>
      <div class="w-50 d-flex justify-content-between">
        <button class="btn-blue w-50 mr-3" @click="createNewConsumer">
          Add Consumer
        </button>
        <button
          v-if="getUserIsAdministrator || getUserIsClientAdmin"
          class="btn-blue w-50"
          @click="editForm"
        >
          Edit Consumer Fields
        </button>
      </div>
    </template>
    <b-modal
      v-b-modal.showRemovalAlert
      id="showRemovalAlert"
      variant="light"
      dismissible
      class="w-100 mx-auto"
    >
      <p>
        Are you sure you want to remove this field? All data for this field will
        be lost.
      </p>
      <div slot="modal-footer">
        <button
          class="btn-sm btn-outline-dark mx-2"
          @click="
            () => {
              removeMetaField(this.metaIndex);
              $bvModal.hide('showRemovalAlert');
            }
          "
        >
          Yes, I'm sure.
        </button>
        <button
          class="btn-sm btn-outline-danger mx-2"
          @click="
            () => {
              $bvModal.hide('showRemovalAlert');
            }
          "
        >
          Cancel
        </button>
      </div>
    </b-modal>
    <b-modal
      v-b-modal.showSaveWarning
      id="showSaveWarning"
      variant="light"
      dismissible
      class="w-100 mx-auto"
    >
      <p>
        Are you sure you want to save your changes? Fields can be reordered, but
        they can <strong>NEVER</strong> have any data with them changed again.
        Make sure everything is correct before your press submit
      </p>
      <div slot="modal-footer">
        <button
          class="btn-sm btn-outline-dark mx-2"
          @click="
            () => {
              saveMetadataForm();
              $bvModal.hide('showSaveWarning');
            }
          "
        >
          Yes, I'm sure.
        </button>
        <button
          class="btn-sm btn-outline-danger mx-2"
          @click="
            () => {
              $bvModal.hide('showSaveWarning');
            }
          "
        >
          Cancel
        </button>
      </div>
    </b-modal>
    <div class="text-center m-4" v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <template v-else>
      <div v-if="consumers != null">
        <div>
          <div
            class="w-100 mb-3"
            v-if="
              Array.isArray(consumerDataFilters) &&
                consumerDataFilters.length > 0
            "
          >
            <input
              type="text"
              v-model="consumerDataFilter"
              @input="filterConsumers"
              @paste="filterConsumers"
              @blur="filterConsumers"
              @change="filterConsumers"
              class="form-control"
              placeholder="Consumer Filter"
            />
          </div>
        </div>
        <div>
          <b-card v-if="consumers">
            <b-row>
              <b-col cols="4" class="d-flex">
                <div class="sort-button" @click="setSort('email')">
                  E-mail
                  <b-spinner
                    v-if="searchLoading && lastSorted === 'email'"
                    variant="primary"
                    label="Spinning"
                    small
                  ></b-spinner>
                  <template v-else>
                    <i
                      v-if="consumerSortSelection === 'email_asc'"
                      class="fas fa-arrow-up"
                    ></i
                    ><i
                      v-if="consumerSortSelection === 'email_desc'"
                      class="fas fa-arrow-down"
                    ></i>
                  </template></div
              ></b-col>
              <b-col cols="2" class="d-flex">
                <div class="sort-button" @click="setSort('id')">
                  Account
                  <b-spinner
                    v-if="searchLoading && lastSorted === 'id'"
                    variant="primary"
                    label="Spinning"
                    small
                  ></b-spinner>
                  <template v-else>
                    <i
                      v-if="consumerSortSelection === 'id_asc'"
                      class="fas fa-arrow-up"
                    ></i
                    ><i
                      v-if="consumerSortSelection === 'id_desc'"
                      class="fas fa-arrow-down"
                    ></i>
                  </template>
                </div>
              </b-col>
              <b-col
                cols="2"
                class="d-flex"
                @click="setFilter(consumerStatusFilter)"
              >
                <div class="sort-button w-50">
                  Status
                  <div
                    v-if="
                      searchLoading &&
                        (lastSorted.includes('subscribe') ||
                          lastSorted.includes('all'))
                    "
                  >
                    <b-spinner
                      variant="primary"
                      label="Spinning"
                      small
                    ></b-spinner>
                  </div>
                  <template v-else>
                    <div
                      class="badge badge-success"
                      v-if="consumerStatusFilter === 'subscribed'"
                    >
                      Subscribed
                    </div>
                    <div
                      class="badge badge-danger"
                      v-if="consumerStatusFilter === 'unsubscribed'"
                    >
                      Unsubscribed
                    </div>
                  </template>
                </div>
              </b-col>
              <b-col cols="3" class="d-flex">
                <div class="sort-button w-75" @click="setSort('created')">
                  Created Date
                  <b-spinner
                    v-if="searchLoading && lastSorted === 'created'"
                    variant="primary"
                    label="Spinning"
                    small
                  ></b-spinner>
                  <template v-else>
                    <i
                      v-if="consumerSortSelection === 'created_asc'"
                      class="fas fa-arrow-up"
                    ></i
                    ><i
                      v-if="consumerSortSelection === 'created_desc'"
                      class="fas fa-arrow-down"
                    ></i>
                  </template>
                </div>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
          </b-card>
          <p v-else-if="consumers.length === 0">
            No consumers found.
          </p>
          <p v-else>
            No consumers attached to this client. Please add a consumer using
            the <strong>Add Consumer</strong> button.
          </p>
        </div>
        <div class="display-table clickable">
          <b-card
            v-for="(consumer, i) in consumersDisplayed"
            :key="i"
            :style="consumersFiltered[i] ? 'display:none' : ''"
            @click.prevent="editConsumer(consumer)"
          >
            <b-row class="display-table-row">
              <b-col class="text-center" cols="4">{{
                consumer.consumer_email
              }}</b-col>
              <b-col cols="2"
                >{{ consumer.meta["firstName"] }}&nbsp;{{
                  consumer.meta["lastName"]
                }}</b-col
              >
              <b-col cols="2">
                <span v-if="consumer.unsubscribed" class="text-danger"
                  >Unsubscribed</span
                >
                <span v-else class="text-success">Subscribed</span>
              </b-col>
              <b-col cols="3" class="assigned-to"
                >created {{ convertDate(consumer.created_datetime) }}</b-col
              >
              <b-col cols="1">
                  <div class="dropdown">
                    <button
                      class="task-dropdown-btn btn ellipsis-btn"
                      type="button"
                      :id="'consumerActions' + consumer.email"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      @click.prevent="$event.stopPropagation()"
                    >
                      <a>&#x22EE;</a>
                    </button>
                    <ul
                      class="dropdown-menu"
                      :aria-labelledby="'consumerActions' + consumer.email"
                    >
                      <li>
                        <a
                          class="dropdown-item"
                          @click="showActivity(consumer.consumer_id)"
                          ><i class="fas fa-history"></i> Activity</a
                        >
                      </li>
                    </ul>
                  </div>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div class="d-flex w-100 mt-3">
          <ul class="pagination mx-auto">
            <li class="page-item" v-if="pagination.page > 1">
              <a
                class="page-link"
                href="javascript:void(0)"
                @click="setPage(pagination.page - 1)"
                >Previous</a
              >
            </li>
            <!-- pages 1 to 4 -->
            <template
              v-for="i in paginationRange(
                1,
                Math.min(pagination.totalPages, 4)
              )"
            >
              <li
                :class="'page-item' + (pagination.page == i ? ' active' : '')"
                :key="'pagination_top_' + i"
              >
                <a
                  class="page-link"
                  href="javascript:void(0)"
                  @click="setPage(i)"
                  >{{ i }}</a
                >
              </li>
            </template>
            <template v-if="pagination.totalPages > 4">
              <template v-if="pagination.page - 4 > 4">
                <li class="page-item disabled">
                  <span class="page-link">...</span>
                </li>
              </template>
              <!-- pages ~5/page - 3 to maxPage / page + 3 -->
              <template
                v-for="i in paginationRange(
                  Math.max(5, pagination.page - 3),
                  Math.min(pagination.page + 3, pagination.totalPages)
                )"
              >
                <li
                  :class="'page-item' + (pagination.page == i ? ' active' : '')"
                  :key="'pagination_top_' + i"
                >
                  <a
                    class="page-link"
                    href="javascript:void(0)"
                    @click="setPage(i)"
                    >{{ i }}</a
                  >
                </li>
              </template>
              <template
                v-if="pagination.totalPages > Math.max(4, pagination.page) + 3"
              >
                <template
                  v-if="pagination.totalPages - 4 > pagination.page + 4"
                >
                  <li class="page-item disabled">
                    <span class="page-link">...</span>
                  </li>
                </template>
                <template
                  v-for="i in paginationRange(
                    Math.max(pagination.totalPages - 3, pagination.page + 4),
                    pagination.totalPages
                  )"
                >
                  <li
                    :class="
                      'page-item' + (pagination.page == i ? ' active' : '')
                    "
                    :key="'pagination_top_' + i"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="setPage(i)"
                      >{{ i }}</a
                    >
                  </li>
                </template>
              </template>
            </template>
            <li
              class="page-item"
              v-if="pagination.page < pagination.totalPages"
            >
              <a
                class="page-link"
                href="javascript:void(0)"
                @click="setPage(pagination.page + 1)"
                >Next</a
              >
            </li>
          </ul>
        </div>
      </div>

      <b-modal size="xl" v-model="showEditModal">
        <b-card class="metadata-container w-100">
          <h2>Consumers Metadata</h2>
          <p>
            Each item below will appear as a field when creating a consumer.
            <br />
            These fields will also be referenced when creating notification
            templates and workflows.
          </p>
          <div class="w-50 mx-auto mb-3">
            <b>Labels:</b>
            <div class="text-left">
              <b-badge variant="info" class="mr-1">Type</b-badge>
              - Indicates the type of data the field stores.
            </div>
            <div class="text-left">
              <b-badge variant="primary" class="mr-1">Reserved</b-badge>
              - Default field that cannot be deleted.
            </div>
            <div class="text-left">
              <b-badge variant="success" class="mr-1">Used</b-badge>
              - Field is used somewhere in the system and cannot be deleted.
            </div>
            <div class="text-left">
              <b-badge variant="warning" class="mr-1">Required</b-badge>
              - Field is required in any form that utilizes it.
            </div>
          </div>
          <ConsumerMetadata :addNewField="addNewField" />
          <draggable
            class="draggable-container"
            v-model="metaForm"
            group="metadata"
            @change="
              (e) => {
                onDropClientConsumerMeta(e);
              }
            "
          >
            <div v-for="(metadata, i) in metaForm" :key="i">
              <div id="draggable-element" class="row">
                <p>
                  <i class="fas fa-grip-vertical"></i>
                  {{
                    metadata.meta.name ||
                      (metadata.meta.editable
                        ? `New Metadata Field #${i + 1}`
                        : metadata.meta.key)
                  }}
                </p>
                <p v-if="metadata.meta.editable" class="font-weight-bold">
                  &lt;New Field&gt;
                </p>
                <p v-else>
                  <b-badge
                    v-if="metadata.meta.type"
                    variant="info"
                    class="mr-1"
                    >{{ metadata.meta.type }}</b-badge
                  >
                  <b-badge
                    v-if="reservedFields.includes(metadata.meta.key)"
                    variant="primary"
                    class="mr-1"
                    >Reserved</b-badge
                  >
                  <b-badge
                    v-if="usedFields.includes(metadata.meta.key)"
                    variant="success"
                    class="mr-1"
                    >Used</b-badge
                  >
                  <b-badge
                    v-if="metadata.meta.required"
                    class="mr-1"
                    variant="warning"
                    >Required</b-badge
                  >
                </p>
                <template v-if="metadata.meta.editable">
                  <div class="pr-1" tabindex="-1">
                    <i
                      class="fas fa-trash mr-2 hover-icon"
                      v-b-modal.showRemovalAlert
                      @click="metaIndex = i"
                      title="Remove Field"
                    ></i>
                    <i
                      class="far fa-edit hover-icon"
                      @click="setMetadataView(i)"
                      title="Edit Field"
                    ></i>
                  </div>
                </template>
                <template v-else>
                  <div class="pr-1">
                    <i
                      class="far fa-copy mr-2 hover-icon"
                      title="Copy Field"
                      @click.stop.prevent="
                        () => {
                          copyMetaKey(metadata.meta.key);
                        }
                      "
                    ></i>
                    <i
                      title="Edit Field"
                      class="far fa-edit hover-icon"
                      @click.stop.prevent="setMetadataView(i)"
                    ></i>
                  </div>
                </template>
              </div>
              <div v-if="!isNaN(metaIndex) && i == metaIndex">
                <div class="edit-meta-data-container">
                  <form>
                    <!-- -->
                    <b-form-group>
                      <div class="col mt-1 mb-1">
                        <div
                          v-if="metadata.meta.editable"
                          class="d-flex row justify-content-between text-left mb-3"
                        >
                          <label class="font-weight-bold w-25"
                            >Field Form Key:</label
                          >
                          <input
                            type="text"
                            v-model="metadata.meta.key"
                            class="form-control w-50"
                            placeholder="Key"
                          />
                          <div
                            class="custom-control custom-switch w-25 text-right"
                            v-if="
                              metadata.meta.type != 'file' &&
                                metadata.meta.type != 'image'
                            "
                          >
                            <input
                              type="checkbox"
                              v-model="metadata.meta.required"
                              class="custom-control-input"
                              id="metaContextRequiredSwitch"
                            />
                            <label
                              class="custom-control-label"
                              for="metaContextRequiredSwitch"
                              >Required ?</label
                            >
                          </div>
                        </div>
                        <template v-else>
                          <div class="row justify-content-between">
                            <label class="font-weight-bold">Key:</label>
                            <p class="text-left">{{ metadata.meta.key }}</p>
                          </div>
                          <div class="row justify-content-between">
                            <label class="font-weight-bold">Required:</label>
                            <p class="text-left">
                              {{ metadata.meta.required ? "True" : "False" }}
                            </p>
                          </div>
                        </template>
                        <div
                          class="d-flex row justify-content-between text-left mb-3"
                        >
                          <label class="font-weight-bold w-25"
                            >Display Name:</label
                          >
                          <input
                            type="text"
                            v-model="metadata.meta.name"
                            class="form-control w-75"
                            placeholder="Name"
                          />
                        </div>
                        <template v-if="metadata.meta.editable">
                          <div
                            class="d-flex row justify-content-between text-left mb-3"
                          >
                            <label class="font-weight-bold w-25">Type:</label>
                            <b-form-select
                              class="w-75"
                              v-model="metadata.meta.type"
                              :options="type_options"
                              @change="resetMetaInContextDefaultValue($event)"
                            ></b-form-select>
                            <span></span>
                          </div>
                          <div
                            class="d-flex row justify-content-between text-left mb-3"
                            v-if="
                              metadata.meta.type != 'file' &&
                                metadata.meta.type != 'image' &&
                                metadata.meta.type != 'date' &&
                                metadata.meta.type != 'date + time'
                            "
                          >
                            <label class="font-weight-bold w-25"
                              >Default:</label
                            >
                            <input
                              v-if="
                                metadata.meta.type == 'currency' ||
                                  metadata.meta.type == 'number'
                              "
                              type="number"
                              placeholder="Default Value (only used if required and older consumers need it)"
                              :disabled="!metadata.meta.required"
                              v-model="metadata.meta.defaultValue"
                              class="form-control w-75"
                            />
                            <input
                              v-else-if="metadata.meta.type == 'text'"
                              type="text"
                              :disabled="!metadata.meta.required"
                              placeholder="Default Value (only used if required and older consumers need it)"
                              v-model="metadata.meta.defaultValue"
                              class="form-control w-75"
                            />
                          </div>
                        </template>
                        <template v-else>
                          <div class="row justify-content-between">
                            <label class="font-weight-bold">Type:</label>
                            <p class="text-left">{{ metadata.meta.type }}</p>
                          </div>
                          <div class="row justify-content-between">
                            <label class="font-weight-bold"
                              >Default Value:</label
                            >
                            <p class="text-left">
                              {{
                                metadata.meta.required &&
                                metadata.meta.type != "file" &&
                                metadata.meta.type != "image"
                                  ? metadata.meta.defaultValue
                                  : "N/A"
                              }}
                            </p>
                          </div>
                        </template>
                      </div>
                    </b-form-group>
                    <!-- -->
                  </form>
                </div>
              </div>
            </div>
          </draggable>
          <p v-if="!metaForm || metaForm.length <= 0">
            Select an item from the widget toolbar to add it here
          </p>
        </b-card>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-between">
            <button
              type="button"
              class="btn-outline-blue"
              @click.stop.prevent="showEditModal = false"
            >
              Close
            </button>
            <button class="btn-blue" v-b-modal.showSaveWarning>
              Save Changes
            </button>
          </div>
        </template>
      </b-modal>

      <b-modal
        v-if="consumerInContext != null"
        v-model="showEditConsumerModal"
        :title="consumerInContext.displayName"
      >
        <form @submit.prevent="onSubmit">
          <b-form-group
            class="striped_rows"
            v-if="Object.keys(getClientInContext.consumer_meta).length > 0"
          >
            <legend>
              <strong>Consumer:</strong>&nbsp;{{
                consumerInContext.consumerEmail
              }}
            </legend>
            <b-form-group class="form-group">
              <div class="form-check text-left">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="1"
                  id="consumerSubscribed"
                  :checked="consumerInContext.subscribed"
                  v-model="consumerInContext.subscribed"
                />
                <label class="form-check-label" for="consumerSubscribed">
                  Subscribed
                </label>
              </div>
            </b-form-group>
            <template v-for="metaName in consumerInContext.metaDisplayOrder">
              <b-row class="striped_form_row mb-2" :key="metaName">
                <b-col sm="12">
                  <label class="float-left ml-2 h6"
                    >{{
                      getClientInContext.consumer_meta[metaName].name ||
                        metaName
                    }}<span
                      v-if="getClientInContext.consumer_meta[metaName].required"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                </b-col>
                <template
                  v-if="
                    getClientInContext.consumer_meta[metaName].type == 'date' ||
                      getClientInContext.consumer_meta[metaName].type ==
                        'date + time'
                  "
                >
                  <b-col
                    :sm="
                      getClientInContext.consumer_meta[metaName].type == 'date'
                        ? '12'
                        : '6'
                    "
                  >
                    <b-form-datepicker
                      :required="
                        getClientInContext.consumer_meta[metaName].required
                      "
                      size="sm"
                      locale="en"
                      v-model="consumerInContext.consumerMeta[metaName].value"
                      placeholder="mm/dd/yyyy"
                      style="height:100%"
                    ></b-form-datepicker>
                  </b-col>
                  <b-col
                    v-if="
                      getClientInContext.consumer_meta[metaName].type ==
                        'date + time'
                    "
                    sm="6"
                  >
                    <b-form-timepicker
                      class="time-picker"
                      :reqiured="
                        getClientInContext.consumer_meta[metaName].required
                      "
                      v-model="
                        consumerInContext.consumerMeta[metaName].timeValue
                      "
                      locale="en"
                      placeholder="00:00:00"
                    ></b-form-timepicker>
                  </b-col>
                </template>
                <b-col
                  sm="12"
                  v-else-if="
                    getClientInContext.consumer_meta[metaName].type ==
                      'currency' ||
                      getClientInContext.consumer_meta[metaName].type ==
                        'number'
                  "
                >
                  <input
                    type="number"
                    :required="
                      getClientInContext.consumer_meta[metaName].required
                    "
                    v-model="consumerInContext.consumerMeta[metaName].value"
                    class="form-control"
                    :placeholder="metaName"
                  />
                </b-col>
                <b-col sm="12" v-else>
                  <input
                    type="text"
                    :required="
                      getClientInContext.consumer_meta[metaName].required
                    "
                    v-model="consumerInContext.consumerMeta[metaName].value"
                    class="form-control"
                    :placeholder="metaName"
                  />
                </b-col>
              </b-row>
            </template>
            <b-row>
              <b-col
                xs="12"
                v-if="
                  !consumerInContext.metaDisplayOrder ||
                    consumerInContext.metaDisplayOrder.length == 0
                "
              >
                There is not metadata associated with this client allowed to be
                assigned to a consumer
              </b-col>
              <b-col xs="12" class="text-left small" v-else>
                <span class="text-danger">*</span> = Required
              </b-col>
            </b-row>
          </b-form-group>
        </form>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-between">
            <button
              type="button"
              class="btn-outline-blue"
              @click.stop.prevent="showEditConsumerModal = false"
            >
              Close
            </button>
            <button
              type="submit"
              class="btn-blue"
              @click.stop.prevent="onSubmit"
            >
              Submit
            </button>
          </div>
        </template>
      </b-modal>

      <b-modal
        v-if="this.newConsumerContext"
        size="md"
        v-model="showNewConsumerModal"
        title="Adding New Consumer"
      >
        <form @submit.prevent="onNewConsumerSubmit">
          <b-form-group class="striped_rows">
            <b-row class="striped_form_row mb-2">
              <b-col sm="12">
                <label class="float-left ml-2 h6"
                  >Email<span class="text-danger">*</span></label
                >
              </b-col>
              <b-col sm="12">
                <input
                  type="email"
                  required="1"
                  v-model="newConsumerContext.consumerEmail"
                  class="form-control"
                />
              </b-col>
            </b-row>
            <template v-for="metaName in newConsumerContext.metaDisplayOrder">
              <b-row class="striped_form_row mb-2" :key="metaName">
                <b-col sm="12">
                  <label class="float-left ml-2 h6"
                    >{{
                      getClientInContext.consumer_meta[metaName].name ||
                        metaName
                    }}<span
                      v-if="getClientInContext.consumer_meta[metaName].required"
                      class="text-danger"
                      >*</span
                    ></label
                  >
                </b-col>
                <template
                  v-if="
                    getClientInContext.consumer_meta[metaName].type == 'date' ||
                      getClientInContext.consumer_meta[metaName].type ==
                        'date + time'
                  "
                >
                  <b-col
                    :sm="
                      getClientInContext.consumer_meta[metaName].type == 'date'
                        ? '12'
                        : '6'
                    "
                  >
                    <b-form-datepicker
                      :required="
                        getClientInContext.consumer_meta[metaName].required
                      "
                      size="sm"
                      locale="en"
                      v-model="newConsumerContext.consumerMeta[metaName].value"
                      placeholder="mm/dd/yyyy"
                      style="height:100%"
                    ></b-form-datepicker>
                  </b-col>
                  <b-col
                    v-if="
                      getClientInContext.consumer_meta[metaName].type ==
                        'date + time'
                    "
                    sm="6"
                  >
                    <b-form-timepicker
                      class="time-picker"
                      :reqiured="
                        getClientInContext.consumer_meta[metaName].required
                      "
                      v-model="
                        newConsumerContext.consumerMeta[metaName].timeValue
                      "
                      locale="en"
                      placeholder="00:00:00"
                    ></b-form-timepicker>
                  </b-col>
                </template>
                <b-col
                  sm="12"
                  v-else-if="
                    getClientInContext.consumer_meta[metaName].type ==
                      'currency' ||
                      getClientInContext.consumer_meta[metaName].type ==
                        'number'
                  "
                >
                  <input
                    type="number"
                    :required="
                      getClientInContext.consumer_meta[metaName].required
                    "
                    v-model="newConsumerContext.consumerMeta[metaName].value"
                    class="form-control"
                  />
                </b-col>
                <b-col sm="12" v-else>
                  <input
                    type="text"
                    :required="
                      getClientInContext.consumer_meta[metaName].required
                    "
                    v-model="newConsumerContext.consumerMeta[metaName].value"
                    class="form-control"
                  />
                </b-col>
              </b-row>
            </template>
            <b-row>
              <b-col xs="12" class="text-left small">
                <span class="text-danger">*</span> = Required
              </b-col>
            </b-row>
          </b-form-group>
        </form>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-between">
            <button
              type="button"
              class="btn-outline-blue"
              @click.stop.prevent="showNewConsumerModal = false"
            >
              Close
            </button>
            <button
              type="submit"
              class="btn-blue"
              @click.stop.prevent="onNewConsumerSubmit"
            >
              Submit
            </button>
          </div>
        </template>
      </b-modal>
    </template>
  </ContentContainer>
</template>

<script>
import { mapGetters } from "vuex";
import ConsumerMetadata from "../consumer/ConsumerMetadata";
import draggable from "vuedraggable";
import moment from "moment";
import ContentContainer from "@/components/templates/ContentContainer";

import { distance, closest } from "fastest-levenshtein";

export default {
  components: { draggable, ConsumerMetadata, ContentContainer },
  data() {
    return {
      consumers: null,
      consumerDataFilters: null,
      consumerDataFilter: "",
      consumersFiltered: [],
      consumerStatusFilter: "subscribed",
      consumerStatusFilters: {
        subscribed: "Subscribed",
        unsubscribed: "Unsubscribed",
        all: "All",
      },
      consumerSortSelection: "id_asc",
      consumerSortOptions: {
        id_asc: {
          display: "Default (ASC)",
          sort: [["consumer_id", "ASC"]],
        },
        id_desc: {
          display: "Default (DESC)",
          sort: [["consumer_id", "DESC"]],
        },
        created_asc: {
          display: "Datetime Created (ASC)",
          sort: [["created_datetime", "ASC"]],
        },
        created_desc: {
          display: "Datetime Created (DESC)",
          sort: [["created_datetime", "DESC"]],
        },
        email_asc: {
          display: "Email (ASC)",
          sort: [["consumer_email", "ASC"]],
        },
        email_desc: {
          display: "Email (DESC)",
          sort: [["consumer_email", "DESC"]],
        },
      },
      reservedFields: [
        "namePrefix",
        "firstName",
        "middleName",
        "lastName",
        "nameSuffix",
        "addressLine1",
        "addressLine2",
        "addressLine3",
        "addressCity",
        "addressState",
        "addressCountry",
        "addressPostalCode",
        "phoneNumber",
      ],
      usedFields: [],
      pagination: {
        page: 0,
        totalPages: 0,
        limit: 100,
      },
      newConsumerContext: null,
      consumerInContext: null,
      lastSorted: null,
      loading: false,
      searchLoading: false,
      clientConsumerMeta: {},
      metaForm: [],
      metaIndex: null,
      clientOptions: [{ value: null, text: "Please select a client" }],
      submitting: false,
      selected: null,
      mode: "overview",
      showEditModal: false,
      showNewConsumerModal: false,
      showEditConsumerModal: false,
      // potential todo: move this to ConsumerMetadata.vue if the fields ever get expanded upon -- itsmore likely to be referenced there.
      type_options: [
        { value: null, text: "Please select type" },
        { value: "text", text: "Text" },
        { value: "date", text: "Date" },
        { value: "date + time", text: "Date + Time" },
        { value: "number", text: "Number" },
        { value: "currency", text: "Currency" },
        { value: "file", text: "File" },
        { value: "image", text: "Image" },
      ],
    };
  },
  watch: {
    $route(to, from) {
      // watching this lets me have route changes while in a thing
      if (
        to.name === "ClientConsumers" &&
        to.params?.actionID !== from.params?.actionID
      ) {
        this.handleActionID();
      }
    },
  },
  async mounted() {
    this.loading = true;

    try {
      await this.$store.commit("setResetBaselineComponent");
      this.$bus.$emit("breadcrumbData", [
        {
          text: "Consumers",
          to: {
            name: "ClientConsumers",
          },
          action: this.returnToOverview,
        },
      ]);
      // index is for checking to see if one can swap directly.
      // get client in context is loaded via the surrounding component
      if (this.getClientInContext) {
        await Promise.all([
          this.searchConsumers(true),
          this.getWorkflows.length === 0 &&
            this.$store.dispatch(
              "getActiveWorkflows",
              { clientid: this.getClientInContext.client_id }
            ),
          !this.getClientNotificationTemplateData?.length &&
            this.$store.dispatch("getClientNotificationTemplates"),
        ]);
      }

      this.handleActionID();

      const handleMatch = (textContent) => {
        if (textContent) {
          const matches = textContent.match(/consumer\.[^\s]*/gm);

          if (matches) {
            matches.forEach((match) => {
              const key = match.replace("consumer.", "");
              if (!this.usedFields.includes(key)) {
                this.usedFields.push(key);
              }
            });
          }
        }
      };

      await Promise.all(
        this.getClientNotificationTemplateData.map(async (template) => {
          if (!template.notification_template_meta) return;

          template.notification_template_meta.forEach((meta) => {
            const textContent = meta.data?.content?.text;
            handleMatch(textContent);
          });
        })
      );

      await Promise.all(
        this.getWorkflows.map(async (workflow) => {
          try {
            const metadata = await this.$store.dispatch(
              "getWorkflowMetadata",
              workflow
            );

            metadata.meta.groups.forEach((group) => {
              group.tasks.forEach((task) => {
                task.widgetMeta.forEach((meta) => {
                  const { type, data } = meta.widget;
                  let textContent;

                  if (type === "Calendar") {
                    textContent = data.content.invite.message;
                  }

                  handleMatch(textContent);
                });
              });
            });
          } catch (err) {
            return err;
          }
        })
      );
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters([
      "getClientInContext",
      "getClientConsumerData",
      "getClients",
      "getUserIsAdministrator",
      "getUserIsClientAdmin",
      "getClientNotificationTemplateData",
      "getWorkflows",
    ]),
  },
  methods: {
    async handleActionID() {
      let actionID = this.$route.params.actionID;

      if (actionID) {
        if (actionID == "metadata") {
          this.$bus.$emit("breadcrumbPush", [
            {
              text: "Edit Metadata",
              to: {
                name: "ClientConsumers",
                params: {
                  actionID: "metadata",
                },
              },
            },
          ]);
          return this.editForm();
        } else if (actionID == "create") {
          this.$bus.$emit("breadcrumbPush", [
            {
              text: "Create New Consumer Metadata",
              to: {
                name: "ClientConsumers",
                params: {
                  actionID: "create",
                },
              },
            },
          ]);
          return this.createNewConsumer();
        }
        let editConsumer = null;
        try {
          actionID = parseInt(actionID, 10);
          if (!isNaN(actionID) && actionID > 0) {
            for (let i in this.consumers) {
              if (this.consumers[i].consumer_id == actionID) {
                editConsumer = this.consumers[i];
                break;
              }
            }
          }
          if (editConsumer != null) {
            await this.editConsumer(editConsumer);
          }
        } catch {
          actionID = null;
          // fall through
          await this.$store.dispatch("createErrors", "Invalid Consumer Action");
        }
      } else {
        this.returnToOverview();
      }
    },
    returnToOverview() {
      if (this.mode != "overview" || this.$route.params?.actionID) {
        this.mode = "overview";
        this.consumerInContext = null;
        this.$bus.$emit("breadcrumbData", [
          {
            text: "Consumers",
            to: {
              name: "ClientConsumers",
            },
            action: this.returnToOverview,
          },
        ]);
        return this.$router
          .push({
            name: "ClientConsumers",
            params: {
              clientID: this.getClientInContext.client_id,
              actionID: null,
            },
          })
          .catch(() => {});
      }
    },
    paginationRange(start, end) {
      // used for pagination to overcome limitations of vue range
      if (start > end) {
        // do not show last page
        return [];
      }
      let rangeArray = [];
      do {
        rangeArray.push(start);
        start++;
      } while (start <= end);
      // return built range array
      return rangeArray;
    },
    setPage(page) {
      if (
        this.loading ||
        isNaN(page) ||
        page < 1 ||
        page > this.pagination.totalPages ||
        !Array.isArray(this.consumers)
      ) {
        return false;
      }
      let slice = page - 1;
      this.pagination.page = page;
      this.consumersDisplayed = this.consumersDisplayed.slice(
        slice * this.pagination.limit,
        (slice + 1) * this.pagination.limit
      );
    },
    resetMetaInContextDefaultValue(newType) {
      if (this.metadataInContext) {
        this.metadataInContext.defaultValue = "";
        this.metadataInContext.dvT = newType == "date + time" ? "" : null;
      }
    },
    removeMetaField(index) {
      if (this.metaForm[index].meta.editable) {
        this.metaForm.splice(index, 1);
        this.metadataInContext = null;
        this.metaIndex = null;
      } else {
        alert("You cannot delete an already set up metadata field");
      }
    },
    setMetadataView(id) {
      if (this.metaIndex === id) this.metaIndex = null;
      else this.metaIndex = id;
    },
    mountMeta() {
      this.metadataInContext = null;
      this.metaIndex = null;
      this.metaForm = [];
      if (this.getUserIsAdministrator || this.getUserIsClientAdmin) {
        this.metaForm = [];
        let metaKeys =
          this.getClientInContext.consumer_meta &&
          typeof this.getClientInContext.consumer_meta == "object"
            ? Object.keys(this.getClientInContext.consumer_meta)
            : [];
        if (metaKeys.length > 0) {
          let clientProxy = this.getClientInContext;
          metaKeys = metaKeys.sort(function(a, b) {
            return (
              (clientProxy.consumer_meta[a].displayOrder || 0) -
              (clientProxy.consumer_meta[b].displayOrder || 0)
            );
          });
          // retain copy to render consumer
          for (let i in metaKeys) {
            this.metaForm.push({
              id: this.getClientInContext.consumer_meta[metaKeys[i]]
                .displayOrder,
              meta: {
                key: metaKeys[i],
                name:
                  this.getClientInContext.consumer_meta[metaKeys[i]].name ||
                  metaKeys[i],
                editable: false,
                required:
                  this.getClientInContext.consumer_meta[metaKeys[i]].required ||
                  false,
                type: this.getClientInContext.consumer_meta[metaKeys[i]].type,
                defaultValue: this.getClientInContext.consumer_meta[metaKeys[i]]
                  .defaultValue,
                dvT: null,
              },
            });
          }
        }
      }
    },
    onDropClientConsumerMeta(e) {
      [this.metadataInContext, this.metaIndex] = [null, null];
      if (e.added) {
        this.metaIndex = e.added.newIndex;
      }
    },
    async setClientConsumers(client) {
      if (this.getUserIsAdministrator && client) {
        try {
          this.loading = true;
          // always change client in context
          await this.$store.dispatch("getClientInContext", client);
          await this.searchConsumers();

          this.consumers = this.getClientConsumerData;
          this.consumerDataFilters = [];
          this.consumersFiltered = [];
          this.consumerDataFilter = "";
          for (let i in this.consumers) {
            let consumerData = [
              this.consumers[i].consumer_email,
              this.consumers[i].consumer_id.toString(),
            ];
            if (this.consumers[i].meta.firstName) {
              consumerData.push(this.consumers[i].meta.firstName);
            }
            if (this.consumers[i].meta.lastName) {
              consumerData.push(this.consumers[i].meta.lastName);
            }
            this.consumersFiltered.push(false);
            this.consumerDataFilters.push(consumerData);
          }
          this.mountMeta();
          this.filterConsumers();
        } finally {
          this.loading = false;
        }
      }
    },
    navigateToEditForm() {
      this.$router
        .push({
          path: "/consumers/metadata",
        })
        .catch(() => {});
    },
    async editForm() {
      if (this.getUserIsAdministrator || this.getUserIsClientAdmin) {
        this.consumerInContext = null;
        this.newConsumerContext = null;
        this.mode = "editForm";
        this.showEditModal = true;
        this.mountMeta();
      } else {
        this.returnToOverview();
      }
    },
    navigateToCreateConsumer() {
      this.$router
        .push({ name: "ClientConsumers", params: { actionID: "create" } })
        .catch(() => {});
    },
    async createNewConsumer() {
      this.consumerInContext = null;
      this.mountMeta();
      this.newConsumerContext = {
        consumerEmail: "",
        consumerMeta: {},
        metaDisplayOrder: [],
      };
      this.generateConsumerMetaForm(null, this.newConsumerContext);
      this.mode = "addNewConsumer";
      this.showNewConsumerModal = true;
    },
    async editConsumer(editConsumer) {
      let displayNameParts = [];
      if (
        editConsumer.meta &&
        (editConsumer.meta["firstName"] || editConsumer.meta["lastName"])
      ) {
        if (editConsumer.meta["namePrefix"]) {
          displayNameParts.push(editConsumer.meta["namePrefix"]);
        }
        if (editConsumer.meta["firstName"]) {
          displayNameParts.push(editConsumer.meta["firstName"]);
        }
        if (editConsumer.meta["middleName"]) {
          displayNameParts.push(editConsumer.meta["middleName"]);
        }
        if (editConsumer.meta["lastName"]) {
          displayNameParts.push(editConsumer.meta["lastName"]);
        }
        if (editConsumer.meta["nameSuffix"]) {
          displayNameParts.push(editConsumer.meta["nameSuffix"]);
        }
      }

      this.consumerInContext = {
        consumerID: editConsumer.consumer_id,
        consumerEmail: editConsumer.consumer_email,
        displayName:
          displayNameParts.length > 0
            ? displayNameParts.join(" ")
            : `Consumer #${editConsumer.consumer_id}`,
        consumerMeta: {},
        metaDisplayOrder: [],
        subscribed: !editConsumer.unsubscribed,
      };

      // if (this.$attrs?.actionID != this.consumerInContext.consumerID) {
      //   this.$router
      //     .push({
      //       name: "ClientConsumers",
      //       params: { actionID: this.consumerInContext.consumerID },
      //     })
      //     .catch(() => {});
      // }
      // this.$bus.$emit("breadcrumbData", [
      //   {
      //     text: "Consumers",
      //     to: {
      //       name: "ClientConsumers",
      //     },
      //     action: this.returnToOverview,
      //   },
      //   {
      //     text: this.consumerInContext.displayName,
      //     to: {
      //       name: "ClientConsumers",
      //       params: {
      //         actionID: this.consumerInContext.consumerID,
      //       },
      //     },
      //   },
      // ]);
      this.showEditConsumerModal = true;
      this.generateConsumerMetaForm(editConsumer, this.consumerInContext);
      this.mode = "editConsumer";
    },
    generateConsumerMetaForm(dbConsumer, consumerContext) {
      if (this.getClientInContext.consumer_meta) {
        let clientProxy = this.getClientInContext;
        let metaKeys = Object.keys(this.getClientInContext.consumer_meta).sort(
          function(a, b) {
            return (
              (clientProxy.consumer_meta[a].displayOrder || 0) -
              (clientProxy.consumer_meta[b].displayOrder || 0)
            );
          }
        );
        consumerContext.metaDisplayOrder = metaKeys;
        let resolveDate = (metaRow, consumerValue, clientMeta) => {
          let date = new Date(consumerValue);
          if (date == "Invalid Date") {
            if (clientMeta.required) {
              date = new Date(clientMeta.defaultValue);
              if (date == "Invalid Date") {
                date = new Date();
              }
            } else {
              date = null;
            }
          }
          if (date !== null) {
            metaRow.value = date.toISOString().substr(0, 10);
            if (metaRow.type == "date + time") {
              metaRow.timeValue = `${date
                .getHours()
                .toString()
                .padStart(2, "0")}:${date
                .getMinutes()
                .toString()
                .padStart(2, "0")}:${date
                .getSeconds()
                .toString()
                .padStart(2, "0")}`;
            }
          } else if (metaRow.type == "date + time") {
            metaRow.timeValue = "";
          }
        };

        // Note: This logic is larely unneeded, but remaining for posterity.
        for (let j in metaKeys) {
          let metaRow = {
            name:
              this.getClientInContext.consumer_meta[metaKeys[j]].name ||
              metaKeys[j],
            value: "",
            timeValue: null,
            type: this.getClientInContext.consumer_meta[metaKeys[j]].type,
            required: this.getClientInContext.consumer_meta[metaKeys[j]]
              .required,
          };
          let resolveAsEmpty = false;
          // if its not there, but resolve "0"
          if (
            !dbConsumer ||
            !dbConsumer.meta ||
            dbConsumer.meta[metaKeys[j]] === undefined ||
            dbConsumer.meta[metaKeys[j]] === "" ||
            dbConsumer.meta[metaKeys[j]] === null
          ) {
            resolveAsEmpty = true;
          } else if (metaRow.type == "date" || metaRow.type == "date + time") {
            resolveDate(
              metaRow,
              dbConsumer.meta[metaKeys[j]],
              this.getClientInContext.consumer_meta[metaKeys[j]]
            );
          } else {
            metaRow.value = dbConsumer.meta[metaKeys[j]];
          }

          if (resolveAsEmpty) {
            if (this.getClientInContext.consumer_meta[metaKeys[j]].required) {
              if (metaRow.type == "date + time" || metaRow.type == "date") {
                resolveDate(
                  metaRow,
                  "invalid date",
                  this.getClientInContext.consumer_meta[metaKeys[j]]
                );
              } else {
                metaRow.value = this.getClientInContext.consumer_meta[
                  metaKeys[j]
                ].defaultValue;
              }
            } else {
              if (
                this.getClientInContext.consumer_meta[metaKeys[j]].type ==
                "date + time"
              ) {
                metaRow.timeValue = "";
              }
            }
          }
          consumerContext.consumerMeta[metaKeys[j]] = metaRow;
        }
      }
    },
    resolveConsumerMetaPayload(consumerContext) {
      let resolveValidDateTime = (function() {
        // Potential TODO: replace with client timezone when its added in, for now assumeoffsets from browser.
        let timezoneOffsetMin = new Date().getTimezoneOffset(),
          offsetStr = `${parseInt(Math.abs(timezoneOffsetMin / 60))
            .toString()
            .padStart(2, "0")}:${Math.abs(timezoneOffsetMin % 60)
            .toString()
            .padStart(2, "0")}`,
          timezoneStandard;

        // Add an opposite sign to the offset
        // If offset is 0, it means timezone is UTC
        if (timezoneOffsetMin < 0) {
          timezoneStandard = "+" + offsetStr;
        } else if (timezoneOffsetMin > 0) {
          timezoneStandard = "-" + offsetStr;
        } else if (timezoneOffsetMin == 0) {
          timezoneStandard = "Z";
        }
        // resolve date with timezone offset built in
        return (date, timeString) => {
          let time = timeString;
          if (!time) {
            time = "00:00:00";
          } else if (time.length == 5) {
            time += ":00";
          } else if (time.length != 8) {
            return false;
          }
          let iso8601 = `${date}T${time}${timezoneStandard}`;
          if (new Date(iso8601) == "Invalid Date") {
            return false;
          }
          return iso8601;
        };
      })();
      let updatePayload = {
        clientID: this.getClientInContext.client_id,
        consumerID: consumerContext.consumerID,
        unsubscribed: !consumerContext.subscribed,
        consumerMeta: {},
      };
      let metaKeys = Object.keys(this.getClientInContext.consumer_meta || {});
      let errors = [];
      for (let i in metaKeys) {
        if (!consumerContext.consumerMeta[metaKeys[i]]) {
          errors.push(
            `Error Updating Consumer: Meta Field "${metaKeys[i]}" was not provided.`
          );
          continue;
        }
        // make sure type isn't funky somehow
        let metaType = this.getClientInContext.consumer_meta[metaKeys[i]].type;
        switch (metaType) {
          case "text":
          case "date":
          case "date + time":
          case "number":
          case "currency":
          case "file":
          case "image":
            break;
          default:
            errors.push(
              `Error Saving Meta: Field "${metaKeys[i]}" has unrecognized type "${metaType}"`
            );
            continue;
        }

        if (
          this.getClientInContext.consumer_meta[metaKeys[i]].type !=
          consumerContext.consumerMeta[metaKeys[i]].type
        ) {
          errors.push(
            `Error Updating Consumer: Meta Field "${metaKeys[i]}" type was not reconciled - please contact support`
          );
          continue;
        }
        if (
          this.getClientInContext.consumer_meta[metaKeys[i]].required &&
          !consumerContext.consumerMeta[metaKeys[i]].value
        ) {
          errors.push(
            `Error Updating Consumer: Meta Field "${metaKeys[i]}" is required`
          );
          continue;
        }
        // calculate value
        let metaValue = "";
        if (
          consumerContext.consumerMeta[metaKeys[i]].value !== undefined &&
          consumerContext.consumerMeta[metaKeys[i]].value !== "" &&
          consumerContext.consumerMeta[metaKeys[i]].value !== null
        ) {
          // validate the type
          let dt = null;
          metaValue = consumerContext.consumerMeta[metaKeys[i]].value;
          switch (metaType) {
            case "date + time":
              // accept time value if 00:00 or 00:00:00 - required entry for date time.
              if (
                typeof consumerContext.consumerMeta[metaKeys[i]].timeValue !=
                  "string" ||
                !consumerContext.consumerMeta[metaKeys[i]].timeValue ||
                (consumerContext.consumerMeta[metaKeys[i]].timeValue.length !=
                  8 &&
                  consumerContext.consumerMeta[metaKeys[i]].timeValue.length !=
                    5)
              ) {
                errors.push(
                  `Error Saving Meta: Required Field "${metaKeys[i]}" of type "${metaType}" has an invalid date/time value is missing time value`
                );
                continue;
              }
            // falls through - remaining date check is the same
            case "date":
              if (typeof metaValue != "string") {
                errors.push(
                  `Error Saving Meta: Required Field "${metaKeys[i]}" of type "${metaType}" has an invalid date/time value`
                );
              }
              dt = resolveValidDateTime(
                metaValue,
                consumerContext.consumerMeta[metaKeys[i]].timeValue
              );
              if (!dt) {
                errors.push(
                  `Error Saving Meta: Required Field "${metaKeys[i]}" of type "${metaType}" has an invalid date/time value`
                );
                continue;
              }
              metaValue = dt;
              break;
            case "number":
            case "currency":
              if (isNaN(metaValue)) {
                errors.push(
                  `Error Saving Meta: Field "${metaKeys[i]}" is a numerical type, value "${metaValue}" is not numeric`
                );
                continue;
              }
              break;
            case "text":
              if (typeof metaValue != "string") {
                let dvString = metaValue.toString();
                // in case of numbers, we're still rejecting arrays or objects.
                if (dvString != metaValue) {
                  errors.push(
                    `Error Saving Meta: Field "${metaKeys[i]}" is a numerical type, value "${metaValue}" is of an unparseable string`
                  );
                  continue;
                }
              }
              metaValue = metaValue.toString();
              break;
            default:
              // currently unsupported: File Uploads.
              metaValue = "";
              break;
          }
        }

        updatePayload.consumerMeta[metaKeys[i]] = metaValue;
      }

      if (errors.length > 0) {
        this.$store.dispatch("createErrors", errors);
        return false;
      }
      return updatePayload;
    },
    async onNewConsumerSubmit() {
      if (this.submitting == (this.submitting = true)) {
        return false;
      }
      try {
        let consumerContext = await this.newConsumerContext;
        if (!consumerContext.consumerEmail) {
          return await this.$store.dispatch(
            "createErrors",
            "Please provide a consumer e-mail."
          );
        }

        let creationPayload = this.resolveConsumerMetaPayload(consumerContext);
        if (creationPayload) {
          creationPayload.consumerEmail = consumerContext.consumerEmail;
          let newConsumer = await this.$store.dispatch(
            "createClientConsumer",
            creationPayload
          );
          if (newConsumer) {
            this.searchConsumers();
            await this.$store.dispatch(
              "createAlerts",
              "Your changes have been saved!"
            );
            return true;
          }
        }
        return false;
      } catch (e) {
        return false;
      } finally {
        this.submitting = false;
        this.showEditConsumerModal = false;
        this.showNewConsumerModal = false;
      }
    },
    async onSubmit() {
      if (this.submitting == (this.submitting = true)) {
        return false;
      }
      try {
        let consumerContext = await this.consumerInContext;
        let updatePayload = this.resolveConsumerMetaPayload(consumerContext);
        if (updatePayload) {
          updatePayload.unsubscribed = !consumerContext.subscribed;
          // build out the payload
          let updatedConsumer = await this.$store.dispatch(
            "updateClientConsumer",
            updatePayload
          );
          if (updatedConsumer) {
            for (let i in this.consumers) {
              if (
                this.consumers[i].consumer_id ==
                this.consumerInContext.consumerID
              ) {
                this.consumers[i] = updatedConsumer;
                await this.editConsumer(updatedConsumer);
                await this.$store.dispatch(
                  "createAlerts",
                  "Your changes have been saved!"
                );
                break;
              }
            }
            return true;
          }
        }
        return false;
      } catch (e) {
        return false;
      } finally {
        this.submitting = false;
        this.showNewConsumerModal = false;
        this.showEditConsumerModal = false;
        await this.searchConsumers(false);
      }
    },
    async saveMetadataForm() {
      const metaForm = await this.metaForm;
      let metaUpdate = {};
      let errors = [];
      let resolvedDateTime = new Date().toISOString();
      let re = /[^a-zA-Z0-9]/g;
      for (let i in metaForm) {
        let metadataField = metaForm[i].meta;

        if (!metadataField.key) {
          errors.push(
            `Error Saving Meta: All fields must have a key, field #${i +
              1} does not`
          );
          continue;
        } else if (re.test(metadataField.key)) {
          errors.push(
            `Error Saving Meta: meta key can only be an letter or a number. One or more non-alphanumeric characters were found in key "${metadataField.key}"`
          );
          continue;
        } else if (metaUpdate[metadataField.key]) {
          errors.push(
            `Error Saving Meta: there are 2 or more fields with the key "${metadataField.key}" - field keys must be unqiue`
          );
          continue;
        }

        if (!metadataField.name) {
          errors.push(
            `Error Saving Meta: All fields must have a name, field #${i +
              1} does not`
          );
          continue;
        }
        // do not need to confirm these beyond this point - just grab the existing value
        if (!metadataField.editable) {
          metaUpdate[metadataField.key] = this.getClientInContext.consumer_meta[
            metadataField.key
          ];
          metaUpdate[metadataField.key].displayOrder = i;
          metaUpdate[metadataField.key].name = metadataField.name;
        } else {
          // make sure type isn't funky somehow
          let metaType =
            metadataField.type && typeof metadataField.type == "string"
              ? metadataField.type.toLowerCase()
              : "";
          switch (metaType) {
            case "text":
            case "date":
            case "date + time":
            case "number":
            case "currency":
            case "file":
            case "image":
              break;
            default:
              errors.push(
                `Error Saving Meta: Field "${metadataField.key}" has unrecognized type "${metaType}"`
              );
              continue;
          }

          let defaultValue = null;
          if (metadataField.required) {
            if (metaType == "file" || metaType == "image") {
              // cannot have defaults or be required
              errors.push(
                `Error Saving Meta: Field "${metadataField.key}" is an uploadable type, and cannot be required or have a backfill value`
              );
              continue;
            } else if (metaType == "date" || metaType == "date + time") {
              // mandatory -- set to now.
              defaultValue = resolvedDateTime;
            } else if (!metadataField.defaultValue) {
              errors.push(
                `Error Saving Meta: Field "${metadataField.key}" is required and requires a default value to backfill in to consumes who do not have it`
              );
              continue;
            }

            // cannot let in switch
            switch (metaType) {
              case "date + time":
              case "date":
                // ignore -- can't have a default, we're defaulting to NOW
                break;
              case "number":
              case "currency":
                if (isNaN(metadataField.defaultValue)) {
                  errors.push(
                    `Error Saving Meta: Field "${metadataField.key}" is a numerical type, value "${metadataField.defaultValue}" is not numeric`
                  );
                  continue;
                }
                defaultValue = metadataField.defaultValue;
                break;
              case "text":
                if (typeof metadataField.defaultValue != "string") {
                  let dvString = metadataField.defaultValue.toString();
                  // in case of numbers, we're still rejecting arrays or objects.
                  if (dvString != metadataField.defaultValue) {
                    errors.push(
                      `Error Saving Meta: Field "${metadataField.key}" is a numerical type, value "${metadataField.defaultValue}" is of an unparseable string`
                    );
                    continue;
                  }
                }
                defaultValue = metadataField.defaultValue.toString();
                break;
              default:
                defaultValue = "";
                break;
            }
          }

          metaUpdate[metadataField.key] = {
            name: metadataField.name,
            type: metaType,
            required: metadataField.required || false,
            defaultValue: defaultValue,
            displayOrder: i,
          };
        }
      }

      if (errors.length > 0) {
        await this.$store.dispatch("createErrors", errors);
        return false;
      }

      let clientUpdate = {
        clientID: await this.getClientInContext.client_id,
        consumerMeta: metaUpdate,
      };

      const updatedClient = await this.$store.dispatch(
        "updateClientInstance",
        clientUpdate
      );

      if (updatedClient) {
        this.setClientConsumers(updatedClient);
        await this.$store.dispatch(
          "createAlerts",
          "Your changes have been saved!"
        );
        this.showEditModal = false;
        return true;
      }
      return false; // api error
    },
    async searchConsumers(useCache) {
      this.searchLoading = true;

      try {
        if (
          !this.consumerStatusFilter ||
          !this.consumerStatusFilters[this.consumerStatusFilter]
        ) {
          this.consumerStatusFilter = "subscribed";
        }

        await this.$store.dispatch("getClientConsumers", {
          sort: this.consumerSortOptions[this.consumerSortSelection].sort,
          forceFetch: !useCache,
        });
        this.consumers = [];
        // add filter variables to this
        this.consumerDataFilters = [];
        this.consumersFiltered = [];
        this.consumerDataFilter = "";
        for (let i in this.getClientConsumerData) {
          if (
            !this.consumerStatusFilter ||
            this.consumerStatusFilter == "all"
          ) {
            this.consumers.push(this.getClientConsumerData[i]);
          } else if (
            this.consumerStatusFilter == "subscribed" &&
            !this.getClientConsumerData[i].unsubscribed
          ) {
            this.consumers.push(this.getClientConsumerData[i]);
          } else if (
            this.consumerStatusFilter == "unsubscribed" &&
            this.getClientConsumerData[i].unsubscribed
          ) {
            this.consumers.push(this.getClientConsumerData[i]);
          } else {
            // skip the rest, do not care about this type of consumer.
            continue;
          }

          let consumerData = [
            this.getClientConsumerData[i].consumer_email,
            this.getClientConsumerData[i].consumer_id.toString(),
          ];
          if (this.consumers[this.consumers.length - 1].meta.firstName) {
            consumerData.push(this.getClientConsumerData[i].meta.firstName);
          }
          if (this.consumers[this.consumers.length - 1].meta.lastName) {
            consumerData.push(this.getClientConsumerData[i].meta.lastName);
          }
          this.consumersFiltered.push(false);
          this.consumerDataFilters.push(consumerData);
        }

        this.pagination.page = 1;
        this.pagination.totalPages = Math.ceil(
          this.consumers.length / this.pagination.limit
        );
        // page is now 0
        this.consumersDisplayed = this.consumers.slice(
          0,
          this.pagination.limit
        );
        this.filterConsumers();
      } finally {
        this.searchLoading = false;
      }
    },
    filterConsumers() {
      if (
        !Array.isArray(this.consumersFiltered) ||
        this.consumersFiltered.length == 0
      ) {
        return;
      }
      if (!this.consumerDataFilter) {
        for (let i = 0; i < this.consumersFiltered.length; i++) {
          this.consumersFiltered[i] = false;
        }
        return;
      }
      // otherwise...f
      let filteredData = this.consumerDataFilter.split(/[^\w\d]/);
      for (let i in this.consumerDataFilters) {
        this.consumersFiltered[i] = !this.consumerDataFilters[i].some(
          (filterVal) => {
            let close = closest(filterVal, filteredData).toLowerCase();
            let fv = filterVal.toLowerCase();
            return (
              fv.includes(close) ||
              1 - distance(fv, close) / (fv.length + close.length) > 0.8
            );
          }
        );
      }
    },
    copyMetaKey(key) {
      navigator.clipboard.writeText(key);
      this.$store.dispatch(
        "createAlerts",
        `${this.getClientInContext?.consumer_meta[key]?.name ||
          key} field key copied`
      );
    },
    convertDate(date) {
      if (!date) {
        // if no date, do not use.
        return null;
      }
      let momentObj = moment(date);
      if (!momentObj || !momentObj.isValid()) {
        return null;
      }
      return momentObj.format("MMMM DD, YYYY h:mm A");
    },
    setSort(sortKey) {
      if (!this.searchLoading) {
        let sortSelection = sortKey;

        if (this.consumerSortSelection === `${sortKey}_asc`)
          sortSelection = `${sortKey}_desc`;
        else if (this.consumerSortSelection === `${sortKey}_desc`)
          sortSelection = `id_asc`;
        else sortSelection = `${sortKey}_asc`;

        this.consumerSortSelection = sortSelection;
        this.lastSorted = sortKey;
        this.searchConsumers();
      }
    },
    setFilter(filterKey) {
      if (!this.searchLoading) {
        let statusFilter = filterKey;

        if (filterKey === "subscribed") statusFilter = "unsubscribed";
        else if (filterKey === "unsubscribed") statusFilter = "all";
        else statusFilter = "subscribed";

        this.consumerStatusFilter = statusFilter;
        this.lastSorted = filterKey;
        this.searchConsumers();
      }
    },
    addNewField(newMetadata) {
      this.metaForm.push(newMetadata);
    },
    async showActivity(consumerId) {
      await this.$router
        .push({
          params: {
            consumerID: consumerId
          },
          name: "ConsumerActivity",
        })
        .catch(async (e) => {
          self.$store.dispatch(
            "createErrors",
            "Could not load consumer activity, please try again later."
          );
        });
    }
  },
};
</script>
